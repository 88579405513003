.mainFilters {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .searchFilter {
        display: flex;
        justify-content: end;
        align-items: flex-end;
        .objects {
            padding-bottom: 5px;
        }
    }


}