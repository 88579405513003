.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /*text-shadow: 5px 4px 11px rgb(0,0,0), 0 2px 5px rgb(0,0,0);*/
    /*text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);*/
    div {
        letter-spacing: 3px;
        width: 218px;
        font-style: italic;
        transform: rotate(-5deg);
        text-align: right;
    }
}

.disp {
    button {
        display: block;
        border: 1px solid #4cb24257;
    }
    :after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 2px;
        background: #4cb242;;
    }
    .name, .role {
        text-transform: none;
        color: black;
        font-family: DINCyr,Arial,sans-serif;
        text-align: right;
    }
    .name {
        font-size: 16px;
    }
    .role {
        font-size: 12px;
    }
}